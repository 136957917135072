import React, { useEffect, useState } from "react";
import { BarsLoader, RotatingLinesLoader } from "../../components/Loaders";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useStateContext } from "../../components/contexts/ContextProvider";
import {
  selectAllTasks,
  selectTaskLoading,
  selectTasksCount,
  getTasks,
  editTasks,
  deleteTasks,
  addTasks,
  updateStatus,
} from "../../redux/features/tasksSlice";
import {
  IoTrashOutline,
  IoCloseOutline,
  IoEyeOutline,
  IoPencil,
} from "react-icons/io5";
import { EditModal, DeleteModal, DetailModal } from "../../components/Modals";
import { TasksSchema } from "../../validation-schemas/schemas";
import moment from "moment";
import Pagination from "../../components/Pagination";
import {
  getAllEmployeesNames,
  selectEmployeeNames,
} from "../../redux/features/employeesSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
const Tasks = () => {
  const {
    portal,
    openDeleteModal,
    closeDeleteModal,
    enable,
    setEnable,
    openEditModal,
    closeEditModal,
    openDetailModal,
    closeDetailModal,
    isToday,
    profile,
    activeProfile,
  } = useStateContext();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const tasks = useSelector(selectAllTasks);
  const loader = useSelector(selectTaskLoading);
  const count = useSelector(selectTasksCount);
  const [errors, setErrors] = useState({});
  const [heading, setHeading] = useState("Add Task");
  const [deleteId, setDeleteId] = useState("");
  const employeeDetailList = useSelector(selectEmployeeNames);

  const [perPage, setPerPage] = useState(20);
  const [page, setPage] = useState(1);
  const [filterChange, setFilterChange] = useState(false);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [detail, setDetail] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [searchData, setSearchData] = useState("");

  const [rowChange, setRowChange] = useState(false);
  const newDate = new Date();
  const formattedDate = moment(newDate).format("YYYY-MM-DD");
  const [status, setStatus] = useState("");
  const empId = portal !== "admin" ? activeProfile?.id : "";
  const [updatedStatus, setUpdatedStatus] = useState(false);

  const initialValues = {
    title: "",
    description: "",
    deadline: formattedDate,
    employee: "",
    assigned_by: profile?.id,
    status: "NOT_STARTED",
    uidb64: "",
  };
  const [formValues, setFormValues] = useState(initialValues);

  const sortTasks = () => {
    const sorted = [...tasks].sort((a, b) => {
      const dateA = new Date(a.deadline);
      const dateB = new Date(b.deadline);
      return dateB - dateA;
    });
    return sorted;
  };

  useEffect(() => {
    setIsInitialLoad(false);
    if (portal === "admin" && activeProfile) {
      dispatch(getAllEmployeesNames({}));
    }
  }, [portal]);

  const filterUseEffect = () => {
    if (filterChange) {
      dispatch(
        getTasks({
          empId,
          employee: searchData,
          status: status,
          page_size: perPage * page,
          page: 1,
          rowChange,
          filterChange,
        })
      );
      setFilterChange(false);
    }
  };

  const rowChangeUseEffect = () => {
    if (tasks.length < count) {
      dispatch(
        getTasks({
          empId,
          employee: searchData,
          status: status,
          page_size: perPage * page,
          page: 1,
          rowChange,
        })
      );
    }
    setRowChange(false);
  };

  const pageChangeUseEffect = () => {
    if (
      tasks.length < (page - 1) * perPage &&
      page === Math.ceil(count / perPage)
    ) {
      dispatch(
        getTasks({
          empId,
          employee: searchData,
          status: status,
          page_size: perPage * page,
          page: 1,
          rowChange: true,
        })
      );
    } else {
      if (
        (tasks.length < page * perPage &&
          (!tasks.length || tasks.length < count) &&
          count !== 0) ||
        isInitialLoad
      ) {
        dispatch(
          getTasks({
            empId,
            employee: searchData,
            status: status,
            page_size: perPage,
            page,
            rowChange,
            isInitialLoad,
          })
        );
      }
    }
  };

  const filterData = () => {
    setSearchData(searchQuery);
    setFilterChange(true);
    setIsFilterApplied(true);
  };

  const ClearFilter = () => {
    dispatch(
      getTasks({
        empId,
        page_size: perPage * page,
        page: 1,
        ClearFilter: true,
      })
    );
    setSearchQuery("");
    setSearchData("");
    setStatus("");
    setIsFilterApplied(false);
  };

  const columns = [
    {
      name: <div className="text-center w-full text-slate-600">Title</div>,
      cell: (row) => (
        <div className="text-center w-full text-slate-500">{row.title}</div>
      ),
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: (
        <div className="text-center w-full text-slate-600">Description</div>
      ),
      cell: (row) => (
        <div className="text-center w-full grid place-content-center">
          <button
            className="cursor-pointer p-2 bg-emerald-500/70 text-slate-50 border-none rounded-lg hover:bg-emerald-500/90 transition-all duration-300"
            onClick={() => {
              setDetail(row.description);
              openDetailModal();
            }}
          >
            <IoEyeOutline className="w-5 h-5 text-inherit" />
          </button>
        </div>
      ),
    },
    ...(portal !== "employee"
      ? [
          {
            name: (
              <div className="text-center w-full text-slate-600">
                Assigned To
              </div>
            ),
            cell: (row) => (
              <div className="text-center w-full text-slate-500">
                {row.employee.employee_name}
              </div>
            ),
          },
        ]
      : []),
    {
      name: <div className="text-center w-full text-slate-600">Deadline</div>,
      cell: (row) => (
        <div className="text-center w-full text-slate-500">
          {isToday(row.deadline)
            ? "Today"
            : moment(row.deadline).format("MMM. DD, YYYY")}
        </div>
      ),
    },
    {
      name: (
        <div className="text-center w-full text-slate-600">Assigned By</div>
      ),
      cell: (row) => (
        <div className="text-center w-full text-slate-500">
          {row.assigned_by.assigned_by_name}
        </div>
      ),
    },
    ...(portal !== "employee"
      ? [
          {
            name: (
              <div className="text-center w-full text-slate-600">
                Added Date
              </div>
            ),
            cell: (row) => (
              <div className="text-center w-full text-slate-500">
                {isToday(row.created_on)
                  ? "Today"
                  : moment(row.created_on).format("MMM. DD, YYYY")}
              </div>
            ),
          },
        ]
      : []),
    {
      name: <div className="text-center w-full text-slate-600">Status</div>,
      cell: (row) => (
        <div className="text-center w-full">
          <span
            className={`p-2 rounded-md text-slate-50 ${
              row.status === "NOT_STARTED"
                ? "bg-sky-600"
                : row.status === "IN_PROGRESS"
                ? "bg-neutral-600"
                : row.status === "BLOCKED"
                ? "bg-red-600"
                : row.status === "DONE"
                ? "bg-emerald-600"
                : "bg-slate-600"
            }`}
          >
            {snakeToTitleCase(row.status)}
          </span>
        </div>
      ),
    },
    ...(portal !== "employee"
      ? [
          {
            name: (
              <div className="text-center w-full text-slate-600">Action</div>
            ),
            cell: (row) => {
              return (
                <div className="w-full flex justify-center gap-2">
                  <button
                    className="cursor-pointer p-2 bg-emerald-500/70 text-slate-50 border-none rounded-lg hover:bg-emerald-500/90 transition-all duration-300"
                    onClick={() => handleEdit(row)}
                  >
                    <IoPencil className="w-5 h-5 text-inherit" />
                  </button>
                  <button
                    className="cursor-pointer p-2 bg-emerald-500/70 text-slate-50 border-none rounded-lg hover:bg-emerald-500/90 transition-all duration-300"
                    onClick={() => {
                      openDeleteModal();
                      setDeleteId(row.id);
                    }}
                  >
                    <IoTrashOutline className="w-5 h-5 text-inherit" />
                  </button>
                </div>
              );
            },
          },
        ]
      : []),
    ...(portal !== "admin"
      ? [
          {
            name: (
              <div className="text-center w-full text-slate-600">
                Update Status
              </div>
            ),
            cell: (row) => {
              return (
                <div className="w-full flex justify-center gap-2">
                  <button
                    className="cursor-pointer p-2 bg-emerald-500/70 text-slate-50 border-none rounded-lg hover:bg-emerald-500/90 transition-all duration-300"
                    onClick={() => handleEdit(row)}
                  >
                    <IoPencil className="w-5 h-5 text-inherit" />
                  </button>
                </div>
              );
            },
          },
        ]
      : []),
  ];

  const snakeToTitleCase = (str) => {
    return str
      .toLowerCase()
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const handleNew = () => {
    setFormValues(initialValues);
    setErrors({});
    setHeading("Add Task");
    openEditModal();
  };

  const handleEdit = (row) => {
    setFormValues({
      ...row,
      employee: row.employee.employee_id,
      assigned_by: row.assigned_by.assigned_by_id,
      uidb64: row.id,
    });
    setErrors({});
    setHeading("Update Task");
    openEditModal();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "status") {
      setUpdatedStatus(true);
    }
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      formValues.uidb64 === "" ||
      formValues.uidb64 === null ||
      formValues.uidb64 === undefined
    ) {
      try {
        try {
          await TasksSchema.validate(formValues, { abortEarly: false });
          dispatch(
            addTasks({
              formValues: formValues,
              toast,
              closeEditModal,
              setEnable,
            })
          );
        } catch (validationErrors) {
          const errors = {};
          if (validationErrors) {
            validationErrors.inner.forEach((error) => {
              errors[error.path] = error.message;
            });
          }
          setErrors(errors);
        }
      } catch (err) {
        console.error("Failed to add Task", err);
      }
    } else {
      try {
        try {
          await TasksSchema.validate(formValues, { abortEarly: false });
          const { uidb64 } = formValues;
          dispatch(
            editTasks({
              uidb64,
              formValues,
              toast,
              closeEditModal,
              setEnable,
            })
          );
        } catch (validationErrors) {
          const errors = {};
          if (validationErrors) {
            validationErrors.inner.forEach((error) => {
              errors[error.path] = error.message;
            });
          }
          setErrors(errors);
        }
      } catch (err) {
        console.error("Failed to update task", err);
      }
    }
  };

  const handleSubmitStatus = (e) => {
    e.preventDefault();

    try {
      if (
        formValues.uidb64 !== "" ||
        formValues.uidb64 !== null ||
        formValues.uidb64
      ) {
        if (updatedStatus) {
          const { uidb64, status } = formValues;
          dispatch(
            updateStatus({
              uidb64,
              formValues: { status },
              toast,
              setEnable,
              closeEditModal,
            })
          );
          setUpdatedStatus(false);
        } else {
          toast.error("Please Update the status to Save");
        }
      }
    } catch (err) {
      console.error("Failed to update status", err);
    }
  };

  const handleDelete = () => {
    dispatch(
      deleteTasks({ uidb64: deleteId, toast, closeDeleteModal, setEnable })
    );
  };

  return (
    <>
      <div>
        <DeleteModal handleDelete={handleDelete} />
        <DetailModal>
          <div className="min-w-[400px] min-h-[200px] bg-white bg-clip-border w-full mx-1 my-2 rounded-md">
            <div className="flex py-3 px-4 items-center justify-between mb-0 bg-gray-100 border-b-[1px] border-solid border-slate-300/25 rounded-t-md">
              <h5 className="mb-0 font-bold text-slate-600 text-lg">
                Description:
              </h5>
              <IoCloseOutline
                className="w-8 h-8 text-slate-500 hover:text-slate-400 transition-all duration-300 cursor-pointer"
                onClick={closeDetailModal}
              />
            </div>
            <div className="p-4">
              <p className="text-slate-500 break-words">{detail}</p>
            </div>
          </div>
        </DetailModal>

        <EditModal>
          <div className="min-w-0 bg-white bg-clip-border w-full mx-1 my-2 rounded-md">
            <div className="flex py-3 px-4 items-center justify-between mb-0 bg-gray-100 border-b-[1px] border-solid border-slate-300/25 rounded-t-md">
              <h5 className="mb-0 font-bold text-slate-600">{heading}</h5>
              <IoCloseOutline
                className="w-9 h-9 text-slate-500 hover:text-slate-400 transition-all duration-300 cursor-pointer"
                onClick={closeEditModal}
              />
            </div>
            <div className="m-1">
              <form
                onSubmit={
                  portal !== "admin" ? handleSubmitStatus : handleSubmit
                }
              >
                <div className="grid md:grid-cols-2 gap-2 mb-1 pb-4 px-1 py-3 border-[1px] border-solid border-slate-300/25 rounded-md">
                  <div>
                    <label className="ml-1 mb-1 text-slate-600">Title</label>
                    <input
                      value={formValues.title}
                      onChange={handleChange}
                      disabled={portal !== "admin"}
                      className="w-full p-2 text-slate-600 bg-clip-padding border-[1px] border-gray-300 border-solid rounded-md"
                      name="title"
                    />

                    {errors.title && (
                      <p className="form-errors">{errors.title}</p>
                    )}
                  </div>
                  <div>
                    <label className="ml-1 mb-1 text-slate-600">DeadLine</label>
                    <input
                      value={formValues.deadline}
                      className="w-full p-2 text-slate-600 bg-clip-padding border-[1px] border-gray-300 border-solid rounded-md"
                      type="date"
                      disabled={portal !== "admin"}
                      onChange={handleChange}
                      name="deadline"
                    />
                    {errors.deadline && (
                      <p className="form-errors">{errors.deadline}</p>
                    )}
                  </div>
                  <div>
                    <label className="ml-1 mb-1 text-slate-600">
                      Assigned To
                    </label>
                    <select
                      value={formValues.employee}
                      disabled={portal !== "admin"}
                      onChange={handleChange}
                      className="w-full p-2  text-slate-600 bg-clip-padding border-[1px] border-gray-300 border-solid rounded-md"
                      name="employee"
                    >
                      <option value="">Select </option>
                      {employeeDetailList
                        ? employeeDetailList
                            .filter(
                              (employee) =>
                                employee.employee_status === "WORKING"
                            )
                            .map((val, index) => (
                              <option key={index} value={val.id}>
                                {val.employee_name}
                              </option>
                            ))
                        : null}
                    </select>
                    {errors.employee && (
                      <p className="form-errors">{errors.employee}</p>
                    )}
                  </div>
                  <div>
                    <label className="ml-1 mb-1 text-slate-600">Status</label>
                    <select
                      value={formValues.status}
                      onChange={handleChange}
                      className="w-full p-2 text-slate-600 bg-clip-padding border-[1px] border-gray-300 border-solid rounded-md"
                      name="status"
                    >
                      <option value="NOT_STARTED">Not Started</option>
                      <option value="IN_PROGRESS">In Progress</option>
                      <option value="DONE">Done</option>
                      <option value="BLOCKED">Blocked</option>
                    </select>
                    {errors.status && (
                      <p className="form-errors">{errors.status}</p>
                    )}
                  </div>
                  <div className="md:col-span-2">
                    <div>
                      <label className="ml-1 mb-1 text-slate-600">
                        Description
                      </label>
                      <textarea
                        value={formValues.description}
                        disabled={portal !== "admin"}
                        onChange={handleChange}
                        className="w-full p-2 text-slate-600 bg-clip-padding border-[1px] border-gray-300 border-solid rounded-md resize-none"
                        rows="5"
                        name="description"
                      />
                      {errors.description && (
                        <p className="form-errors">{errors.description}</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="flex flex-wrap items-center justify-end py-3 px-4 border-t-[1px] border-solid border-gray-200">
                  <div className="flex gap-4">
                    {enable ? (
                      <button
                        className="px-3 py-2 bg-emerald-500/70 hover:bg-emerald-500/90 text-slate-50 font-semibold rounded-md cursor-pointer  transition-all duration-300"
                        type="submit"
                      >
                        Save
                      </button>
                    ) : (
                      <div className="text-slate-50 font-semibold px-4 py-[9px] bg-emerald-500/70 hover:bg-emerald-500/90 rounded-md">
                        <RotatingLinesLoader />
                      </div>
                    )}
                    <button
                      type="button"
                      className="px-3 py-2 bg-gray-500/70 hover:bg-gray-500/90 text-slate-50 font-semibold rounded-md cursor-pointer transition-all duration-300"
                      onClick={() => closeEditModal()}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </EditModal>

        <div className="flex flex-col justify-between lg:flex-row items-center mx-3">
          <div className="flex flex-col min-w-0 bg-white bg-clip-border w-full">
            <div className="mb-0 py-2 px-3 bg-gray-100 border-b-[1px] border-solid border-slate-300/25 flex flex-col md:flex-row items-start md:items-center justify-between">
              <div>
                <h5 className="md:mb-0 max-md:mb-2 font-bold text-slate-600">
                  Tasks
                </h5>
              </div>
              <div className="flex flex-col md:flex-row gap-1 items-start md:items-center max-md:mt-2">
                {portal !== "employee" && (
                  <div className="relative">
                    <input
                      type="text"
                      placeholder="Search Employees"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      className="rounded-lg p-2 w-40 h-10 border-2 border-solid border-emerald-500/70 focus:border-emerald-500/90 text-slate-600 focus:ring-0 "
                    />
                  </div>
                )}

                <div>
                  <select
                    className="rounded-lg p-2 border-2 border-solid border-emerald-500/70 focus:border-emerald-500/90 text-slate-600"
                    id="sts"
                    value={status}
                    onChange={(e) => {
                      setStatus(e.target.value);
                    }}
                  >
                    <option value="">Select Status</option>
                    <option value="NOT_STARTED">Not Started</option>
                    <option value="IN_PROGRESS">In Progress</option>
                    <option value="DONE">Done</option>
                    <option value="BLOCKED">Blocked</option>
                  </select>
                </div>
                <div>
                  <button
                    type="button"
                    className="cursor-pointer px-3 py-2 bg-emerald-500/70 border-none rounded-lg hover:bg-emerald-500/90 transition-all duration-300 text-slate-50"
                    onClick={() => filterData()}
                  >
                    Apply Filter
                  </button>
                </div>
                {isFilterApplied && (
                  <div>
                    <button
                      type="button"
                      className="cursor-pointer px-3 py-2 bg-red-500 border-none rounded-lg hover:bg-emerald-500/90 transition-all duration-300 text-slate-50 max-md:w-full"
                      onClick={() => ClearFilter()}
                    >
                      {" "}
                      <FontAwesomeIcon icon={faTimes} className="mr-2" />
                      Clear Filter
                    </button>
                  </div>
                )}
                {portal !== "employee" && (
                  <div className="max-md:my-2">
                    <button
                      className="cursor-pointer px-3 py-2 bg-emerald-500/70 border-none rounded-lg hover:bg-emerald-500/90 transition-all duration-300 text-slate-50"
                      onClick={handleNew}
                      style={{ marginLeft: "30px" }}
                    >
                      Add Tasks
                    </button>
                  </div>
                )}
              </div>
            </div>
            <div className="card-body">
              <Pagination
                data={portal !== "admin" ? sortTasks() : tasks}
                filterChange={filterChange}
                filterUseEffect={filterUseEffect}
                setRowChange={setRowChange}
                rowChangeUseEffect={rowChangeUseEffect}
                pageChangeUseEffect={pageChangeUseEffect}
                isLoading={loader}
                count={count}
                columns={columns}
                page={page}
                perPage={perPage}
                setPerPage={setPerPage}
                setPage={setPage}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Tasks;
