import React, { useEffect, useState } from "react";
import { getEmployeeProjects, selectEmployeeProjects } from "../../redux/features/projectsSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../components/Pagination";
import { useStateContext } from "../../components/contexts/ContextProvider";
import { selectUser } from "../../redux/features/authSlice";
import { toast } from "react-toastify";
import { IoEye } from "react-icons/io5";

const EmpProject = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { projects, loading, error } = useSelector((state) => {

    return {
      projects: state.projects.employeeProjects || [],
      loading: state.projects.loading,
      error: state.projects.error
    };
  });


  const [isInitialLoad, setIsInitialLoad] = useState(false);
  const { count } = useSelector((state) => state.projects);
  const { portal } = useStateContext();
  

  const [searchQuery, setSearchQuery] = useState("");
  const [searchData, setSearchData] = useState("");
  const [formValues, setFormValues] = useState({
    status: ""
  });
  const [perPage, setPerPage] = useState(20);
  const [page, setPage] = useState(1);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [rowChange, setRowChange] = useState(false);
  const [filterChange, setFilterChange] = useState(false);
  const [sortConfig, setSortConfig] = useState({
    key: "title",
    direction: "asc"
  });
  const [noResults, setNoResults] = useState(false);

  const getStatusDisplayText = (status) => {
    const statusMap = {
      'not_started': 'Not Started',
      'in_progress': 'In Progress',
      'completed': 'Completed',
      'on_hold': 'On Hold',
      'canceled': 'Canceled'
    };
    return statusMap[status] || status;
  };

  const columns = [
    {
      name: <div className="text-center w-full text-slate-600">Project Title</div>,
      cell: (row) => (
        <div className="text-center w-full text-slate-500">{row.project_title}</div>
      ),
    },
    {
      name: <div className="text-center w-full text-slate-600">Status</div>,
      cell: (row) => (
        <div className="text-center w-full text-slate-500">
          {getStatusDisplayText(row.project_status)}
        </div>
      ),
    },
    {
      name: <div className="text-center w-full text-slate-600">Start Date</div>,
      cell: (row) => (
        <div className="text-center w-full text-slate-500">{row.start_date}</div>
      ),
    },
    {
      name: <div className="text-center w-full text-slate-600">End Date</div>,
      cell: (row) => (
        <div className="text-center w-full text-slate-500">{row.end_date}</div>
      ),
    },
    {
      name: <div className="text-center w-full text-slate-600">Engagement Type</div>,
      cell: (row) => (
        <div className="text-center w-full text-slate-500">
          {row.engagement_type.replace('_', ' ').charAt(0).toUpperCase() + 
           row.engagement_type.slice(1).replace('_', ' ')}
        </div>
      ),
    },
    {
      name: <div className="text-center w-full text-slate-600">Team Lead</div>,
      cell: (row) => (
        <div className="text-center w-full text-slate-500">
          {row.project_teamlead || 'N/A'}
        </div>
      ),
    },
    {
      name: <div className="text-center w-full text-slate-600">Action</div>,
      cell: (row) => (
        <div className="w-full flex justify-center">
          <button
            className="cursor-pointer p-2 bg-emerald-500/70 text-slate-50 border-none rounded-lg hover:bg-emerald-500/90 transition-all duration-300"
            onClick={() => handleView(row)}
          >
            <IoEye className="w-5 h-5 text-inherit" />
          </button>
        </div>
      ),
    }
  ];

  const fetchProjects = (params = {}) => {
    const queryParams = {
      title: params.search || searchData,
      status: params.status || formValues.status,
    
    };
   
    return dispatch(getEmployeeProjects(queryParams))
      .then((action) => {
        if (!action.payload || !action.payload.assignments || action.payload.assignments.length === 0) {
          setNoResults(true);
        } else {
          setNoResults(false);
        }
      })
      .catch((error) => {
        toast.error("Failed to fetch projects");
        setNoResults(true);
      });
  };

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const filterData = () => {
    setSearchData(searchQuery);
    setFilterChange(true);
    setIsFilterApplied(true);
    setPage(1);
    fetchProjects({ 
      search: searchQuery, 
      status: formValues.status,
      page: 1,
      filterChange: true 
    });
  };

  const clearFilter = () => {
    setSearchQuery("");
    setSearchData("");
    setFormValues(prev => ({
      ...prev,
      status: ""
    }));
    setIsFilterApplied(false);
    setPage(1);
    setNoResults(false);
    setSortConfig({ key: "title", direction: "asc" });
    setFilterChange(true);
    
    fetchProjects({ 
      search: "", 
      status: "", 
      page: 1,
      perPage: 20
    });
  };

  useEffect(() => {
    if (filterChange || rowChange || page !== 1 || perPage !== 20) {
      fetchProjects({
        rowChange,
        filterChange
      });
      setFilterChange(false);
    }
  }, [filterChange, rowChange, page, perPage]);

  useEffect(() => {
    setIsInitialLoad(false);
    fetchProjects({ isInitialLoad: true });
  }, [dispatch, portal]);

  const handleView = (row) => {
    navigate(`/projects/${row.project_id}`, { state: { userRole: 'employee' } });
  };

  if (error) {
    return <div>Error loading projects: {error}</div>;
  }



  return (
    <div className="flex flex-col justify-between lg:flex-row items-center mx-3">
      <div className="flex flex-col min-w-0 bg-white bg-clip-border w-full">
        <div className="mb-0 py-2 px-3 bg-gray-100 border-b-[1px] border-solid border-slate-300/25 flex flex-col md:flex-row items-start md:items-center justify-between flex-wrap">
          <h5 className="md:mb-0 max-md:mb-2 font-bold text-slate-600">
            Projects
          </h5>

          <div className="flex md:flex-row gap-2 md:items-center max-md:items-start flex-wrap">
            <div className="relative">
              <input
                type="text"
                placeholder="Search Projects"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="rounded-lg p-2 w-40 h-10 border-2 border-solid border-emerald-500/70 focus:border-emerald-500/90 text-slate-600 focus:ring-0"
              />
            </div>
            <div className="">
              <select
                className="rounded-lg p-2 border-2 border-solid border-emerald-500/70 focus:border-emerald-500/90 text-slate-600"
                value={formValues.status}
                onChange={(e) => setFormValues(prev => ({
                  ...prev,
                  status: e.target.value
                }))}
              >
                <option value="">All Status</option>
                <option value="not_started">Not Started</option>
                <option value="in_progress">In Progress</option>
                <option value="completed">Completed</option>
                <option value="on_hold">On Hold</option>
                <option value="canceled">Canceled</option>
              </select>
            </div>
            <button
              type="button"
              className="cursor-pointer px-3 py-2 bg-emerald-500/70 border-none rounded-lg hover:bg-emerald-500/90 transition-all duration-300 text-slate-50"
              onClick={filterData}
            >
              Apply Filter
            </button>
            {isFilterApplied && (
              <button
                type="button"
                className="cursor-pointer px-3 py-2 bg-red-500 border-none rounded-lg hover:bg-emerald-500/90 transition-all duration-300 text-slate-50"
                onClick={clearFilter}
              >
                Clear Filter
              </button>
            )}
          </div>
        </div>
        <div className="card-body">
          {noResults ? (
            <div className="flex justify-center items-center p-8">
              <div className="text-center">
                <p className="text-lg text-gray-500 mb-2">No projects found</p>
                <p className="text-sm text-gray-400">
                  Try adjusting your search or filter criteria
                </p>
              </div>
            </div>
          ) : (
            <Pagination
              data={projects}
              filterChange={filterChange}
              setRowChange={setRowChange}
              isLoading={loading}
              count={count}
              columns={columns}
              page={page}
              perPage={perPage}
              setPerPage={setPerPage}
              setPage={setPage}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default EmpProject;
