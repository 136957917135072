import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as api from "../api";

const initialState = {
  loading: false,
  projectLoading: false,
  allProjects: [],
  project: [],
  count: 0,
  error: "",
  projects: [],
  accountManagers: [],
  accountManagersLoading: false,
  employeeProjects: [],
};

export const getAllProjects = createAsyncThunk(
  "projects/getAllProjects",
  async ({ searchData, status, page_size, page, portal, toast, navigate }, { rejectWithValue }) => {
    try {
      const params = {
        title: searchData || "",
        status: status || "",
        page_size: page_size || 10,
        page: page || 1,
        portal
      };

      const response = await api.getProjects(params);
      return response.data;
    } catch (error) {
      if (toast) {
        toast.error(error.response?.data?.message || "Failed to fetch projects");
      }
      return rejectWithValue(error.response?.data || { message: "Unknown error occurred" });
    }
  }
);

// Fetch a single project
export const getProject = createAsyncThunk(
  "projects/getProject",
  async ({ projectId }, { rejectWithValue }) => {
    try {
      const response = await api.getProjectDetails(projectId);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Create a new project
export const addProject = createAsyncThunk(
  "projects/addProject",
  async ({ formValues, toast, setEnable }, { rejectWithValue }) => {
    try {
      setEnable(false);
      const response = await api.addProject(formValues);
      toast.success("Project Added");
      setEnable(true);
      return response.data;
    } catch (error) {
      setEnable(true);
      const errors = error.response.data;
      if (errors) {
        toast.error(Object.values(errors)[0][0]);
      }
      return rejectWithValue(Object.values(errors)[0]);
    }
  }
);

// Edit an existing project
export const editProject = createAsyncThunk(
  "projects/editProject",
  async ({ projectId, formValues, toast, closeEditModal, setEnable }, { rejectWithValue }) => {
    try {
      setEnable(false);
      const response = await api.editProject(projectId, formValues);
      toast.success("Project Updated");
      closeEditModal();
      setEnable(true);
      return response.data;
    } catch (error) {
      setEnable(true);
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data.message);
    }
  }
);

// Delete a project
export const deleteProject = createAsyncThunk(
  "projects/deleteProject",
  async ({ projectId, toast, closeDeleteModal, setEnable }, { rejectWithValue }) => {
    try {
      if (setEnable) setEnable(false);
      await api.deleteProject(projectId);
      if (toast) toast.success("Project Deleted Successfully");
      if (closeDeleteModal) closeDeleteModal();
      if (setEnable) setEnable(true);
      return projectId;
    } catch (error) {
      if (setEnable) setEnable(true);
      if (toast) toast.error(error.response?.data?.message || "Failed to delete project");
      return rejectWithValue(error.response?.data);
    }
  }
);



// Selector to get all projects
export const selectAllProjects = (state) => state.projects.allProjects;

export const selectProjectCount = (state) => state.projects.count;

// Get all account managers
export const getAllAccountManagers = createAsyncThunk(
  "projects/getAllAccountManagers",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.getAllAccountManagers();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Failed to fetch account managers");
    }
  }
);



// Selector for account managers

export const selectAllAccountManagers = (state) => state.projects.accountManagers;

// Add these new action creators
export const addTeamMember = createAsyncThunk(
  "projects/addTeamMember",
  async ({ formData, toast }, { rejectWithValue, dispatch }) => {
    try {
      const response = await api.createAssignment(formData);
      toast.success("Team member added successfully");
      // Refresh the project data
      dispatch(getAllProjects({}));
      return response.data;
    } catch (error) {
      toast.error(error.response?.data?.message || "Failed to add team member");
      return rejectWithValue(error.response?.data);
    }
  }
);

export const updateTeamMember = createAsyncThunk(
  "projects/updateTeamMember",
  async ({ assignmentId, formData, toast }, { rejectWithValue, dispatch }) => {
    try {
      const response = await api.updateAssignment(assignmentId, formData);
      toast.success("Team member updated successfully");
      // Refresh the project data
      dispatch(getAllProjects({}));
      return response.data;
    } catch (error) {
      toast.error(error.response?.data?.message || "Failed to update team member");
      return rejectWithValue(error.response?.data);
    }
  }
);

export const removeTeamMember = createAsyncThunk(
  "projects/removeTeamMember",
  async ({ assignmentId, toast }, { rejectWithValue, dispatch }) => {
    try {
      await api.deleteAssignment(assignmentId);
      toast.success("Team member removed successfully");
      // Refresh the project data
      dispatch(getAllProjects({}));
      return assignmentId;
    } catch (error) {
      toast.error(error.response?.data?.message || "Failed to remove team member");
      return rejectWithValue(error.response?.data);
    }
  }
);


export const getEmployeeProjects = createAsyncThunk(
  "projects/getEmployeeProjects",
  async (queryParams, { rejectWithValue }) => {
    try {
      const response = await api.getEmployeeProjects(queryParams);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const getTeamLeadProjects = createAsyncThunk(
  "projects/getTeamLeadProjects",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.getTeamLeadProjects();
      console.log(response.data, "response.data");
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Failed to fetch team lead projects");
    }
  }
);

export const selectEmployeeProjects = (state) => state.projects.employeeProjects;

const projectsSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllProjects.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllProjects.fulfilled, (state, action) => {
        state.loading = false;
        state.count = action.payload.count;
        
        if (!action.meta.arg.page || 
            action.meta.arg.rowChange ||
            action.meta.arg.filterChange || 
            action.meta.arg.ClearFilter || 
            action.meta.arg.isInitialLoad) {
          state.allProjects = action.payload.results;
        } else {
          state.allProjects = state.allProjects.concat(action.payload.results);
        }
      })
      .addCase(getAllProjects.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteProject.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteProject.fulfilled, (state, action) => {
        state.loading = false;
        state.allProjects = state.allProjects.filter((project) => project.id !== action.payload);
      })
      .addCase(deleteProject.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(addProject.pending, (state) => {
        state.loading = true;
      })
      .addCase(addProject.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(addProject.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(editProject.pending, (state) => {
        state.loading = true;
      })
      .addCase(editProject.fulfilled, (state, action) => {
        state.loading = false;
        state.allProjects = state.allProjects.map((project) =>
          project.id === action.payload.id ? action.payload : project
        );
      })
      .addCase(editProject.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getAllAccountManagers.pending, (state) => {
        state.accountManagersLoading = true;
      })
      .addCase(getAllAccountManagers.fulfilled, (state, action) => {
        state.accountManagersLoading = false;
        state.accountManagers = action.payload.account_managers || [];
      })
      .addCase(getAllAccountManagers.rejected, (state) => {
        state.accountManagersLoading = false;
      })
      .addCase(addTeamMember.pending, (state) => {
        state.loading = true;
      })
      .addCase(addTeamMember.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(addTeamMember.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateTeamMember.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateTeamMember.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updateTeamMember.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(removeTeamMember.pending, (state) => {
        state.loading = true;
      })
      .addCase(removeTeamMember.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(removeTeamMember.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getEmployeeProjects.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getEmployeeProjects.fulfilled, (state, action) => {
        state.loading = false;
        state.count = action.payload.count;
        
        if (!action.meta.arg.page || 
            action.meta.arg.rowChange ||
            action.meta.arg.filterChange || 
            action.meta.arg.ClearFilter || 
            action.meta.arg.isInitialLoad) {
          state.employeeProjects = action.payload.assignments;
        } else {
          state.employeeProjects = state.employeeProjects.concat(action.payload.assignments);
        }
      })
      .addCase(getEmployeeProjects.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.employeeProjects = [];
      })
      .addCase(getTeamLeadProjects.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTeamLeadProjects.fulfilled, (state, action) => {
        state.loading = false;
        state.teamLeadProjects = action.payload.assignments;
      })
      .addCase(getTeamLeadProjects.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default projectsSlice.reducer;
