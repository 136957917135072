import React, { useEffect, useState } from "react";
import {
    IoPencil,
    IoTrashOutline,
    IoCloseOutline, IoChevronBackSharp, IoEyeOutline
} from "react-icons/io5";
import {
    selectEmployeeNames, getAllEmployeesNames,
} from "../../redux/features/employeesSlice";
import {
     selectRecruitHistoryCount, getRecruiterHistory,
    addRecruiterHistory, editRecruiterHistory, deleteRecruiterHistory, selectAllRecruitHistory
} from "../../redux/features/recruiterSlice";
import moment from "moment";
import { toast } from "react-toastify";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
    ApplicantHistorySchema
} from "../../validation-schemas/schemas";
import { useStateContext } from "../../components/contexts/ContextProvider";
import {EditModal, DeleteModal, DetailModal} from "../../components/Modals";
import Pagination from "../../components/Pagination";
import {RotatingLinesLoader} from "../../components/Loaders";

const RecruitHistory = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { recruitId } = useParams();
    const {recruitName} = useParams()
    const employeeDetailList = useSelector(selectEmployeeNames)
    const [deleteId, setDeleteId] = useState("");

    const applicants = useSelector(selectAllRecruitHistory);
    const count = useSelector(selectRecruitHistoryCount)
    const loading = useSelector((state) => state.recruiter.historyLoading);


    const [errors, setErrors] = useState({});
    const [heading, setHeading] = useState("Add Applicant History");
    const [isInitialLoad, setIsInitialLoad] = useState(true);

    const [perPage, setPerPage] = useState(20);
    const [page, setPage] = useState(1)

    const [rowChange, setRowChange] = useState(false);
    const [history, setHistory] = useState("");
    const [addedName, setAddedName] = useState("");
    const [addedDate, setAddedDate] = useState("");


    const {
        openEditModal,
        closeEditModal,
        enable,
        setEnable,
        openDeleteModal,
        closeDeleteModal,
        isToday,
        openDetailModal,
        closeDetailModal
    } = useStateContext();

    const initialValues = {
        recruit: recruitId,
        process_stage: "",
        remarks: "",
        event_date: "",
        conduct_by: "",
        added_by: JSON.parse(localStorage.profile).id,
        uidb64: ""
    };

    const [formValues, setFormValues] = useState(initialValues);

   useEffect(() => {
     setIsInitialLoad(false)
     if(!employeeDetailList.length){
      dispatch(getAllEmployeesNames({}))}
   }, []);

   const rowChangeUseEffect =() => {
    if(applicants.length < count){
    dispatch(getRecruiterHistory({
         id: recruitId, page_size: perPage *page , page:1, rowChange
        }) ) }
    setRowChange(false)
  }

  const pageChangeUseEffect =() => {
      if (applicants.length < (page - 1) * perPage && page === Math.ceil(count / perPage)) {
          dispatch(getRecruiterHistory({
              id: recruitId, page_size: perPage * page, page: 1, rowChange: true
          }))
      } else {
          if ((applicants.length < page * perPage && (!applicants.length || applicants.length < count)) || isInitialLoad) {
              dispatch(getRecruiterHistory({
                  id: recruitId, page_size: perPage, page, rowChange, isInitialLoad
              }))
          }
      }
  }

    const columns = [
      {
        name: ( <div className="text-center w-full text-slate-600">Stage</div> ),
        cell: (row) => (
          <div className="text-center w-full text-slate-500">{row.process_stage}</div>
        ),
      },
      {
        name: (
          <div className="text-center w-full text-slate-600">Remarks</div>
        ),
        cell: (row) => (
          <div className="text-center w-full grid place-content-center">
          <button
            className="cursor-pointer p-2 bg-emerald-500/70 text-slate-50 border-none rounded-lg hover:bg-emerald-500/90 transition-all duration-300"
            onClick={() => {
              setHistory(row.remarks);
              setAddedName(row.added_by ? row.added_by.added_by_name : '');
              setAddedDate(
                isToday(row.added_date)
                  ? "Today"
                  : moment(row.added_date).format("MMM. DD, YYYY")
              );
              openDetailModal();
            }}
          >
            <IoEyeOutline className="w-5 h-5 text-inherit text-slate-50" />
          </button>
          </div>
        ),
      },
      {
        name: (
          <div className="text-center w-full text-slate-600">Event Date</div>
        ),
        cell: (row) => (
          <div className="text-center w-full text-slate-500">
            {isToday(row.event_date)
            ? "Today"
            : moment(row.event_date).format("MMM. DD, YYYY")}
          </div>
        ),
      },
      {
        name: <div className="text-center w-full text-slate-600">Conduct By</div>,
        cell: (row) => (
          <div className="text-center w-full text-slate-500">
            {row.conduct_by ? row.conduct_by.conduct_by_name : ''}
          </div>
        ),
      },
      // {
      //   name: <div className="text-center w-full text-slate-600">Added By</div>,
      //   cell: (row) => (
      //     <div className="text-center w-full text-slate-500">
      //       {row.added_by ? row.added_by.added_by_name : ''}
      //     </div>
      //   ),
      // },
      // {
      //   name: <div className="text-center w-full text-slate-600">Added Date</div>,
      //   cell: (row) => (
      //     <div className="text-center w-full text-slate-500">
      //       {isToday(row.added_date)
      //       ? "Today"
      //       : moment(row.added_date).format("MMM. DD, YYYY")}
      //     </div>
      //   ),
      // },
      {
      name: <div className="text-center w-full text-slate-600">Action</div>,
      cell: (row) => {
        return (
          <div className="w-full flex justify-center gap-2">

            <button
              className="cursor-pointer p-2 bg-emerald-500/70 text-slate-50 border-none rounded-lg hover:bg-emerald-500/90 transition-all duration-300"
              onClick={() => handleEdit(row)}
            >
              <IoPencil className="w-5 h-5 text-inherit" />
            </button>
            <button
              className="cursor-pointer p-2 bg-emerald-500/70 text-slate-50 border-none rounded-lg hover:bg-emerald-500/90 transition-all duration-300"
              onClick={() => {
                openDeleteModal();
                setDeleteId(row.id);
              }}
            >
              <IoTrashOutline className="w-5 h-5 text-inherit" />
            </button>
          </div>
        );
      },
    },
    ];
    const handleSubmit = async (e) => {
        e.preventDefault();
        const { uidb64 } = formValues;
        if (
          formValues.uidb64 === "" ||
          formValues.uidb64 === null ||
          formValues.uidb64 === undefined
        ) {
      try {
        const updatedValues = {
          ...formValues,
        };
        try {
          await ApplicantHistorySchema.validate(formValues, { abortEarly: false });
          dispatch(
            addRecruiterHistory({
              formValues: { ...updatedValues },
              toast,
              navigate,
              setEnable,
              closeEditModal,
            })
          );
        } catch (validationErrors) {
          const errors = {};
          if (validationErrors) {
            validationErrors.inner.forEach((error) => {
              errors[error.path] = error.message;
            });
          }
          setErrors(errors);
        }
      } catch (err) {
        console.error("Failed to add employee history", err);
      }
    } else {
      try {
        try {
          await ApplicantHistorySchema.validate(formValues, { abortEarly: false });
            dispatch(
              editRecruiterHistory({
                uidb64,
                formValues,
                toast,
                closeEditModal,
                setEnable,
              })
            );
        } catch (validationErrors) {
          const errors = {};
          if (validationErrors) {
            validationErrors.inner.forEach((error) => {
              errors[error.path] = error.message;
            });
          }
          setErrors(errors);
        }
      } catch (err) {
        console.error("Failed to update employee", err);
      }
    }
  };

    const handleEdit = (row) => {
    setFormValues({
      ...row,
      conduct_by: row.conduct_by.conduct_by_id,
      recruit: recruitId,
      added_by: row.added_by.added_by_id,
      uidb64: row.id,
    });

    setErrors({});
    setHeading("Update Applicant History");
    openEditModal();
  };

  const handleDelete = () => {
    dispatch(
      deleteRecruiterHistory({ uidb64: deleteId, toast, closeDeleteModal, setEnable })
    );
  };

  const handleChange = (e) => {
    let { name, value } = e.target;
     setFormValues({ ...formValues, [name]: value });
  };

    const handleNew = () => {
    setFormValues(initialValues);
    setErrors({});
    setHeading("Add Applicant History");
    openEditModal();
  };
    return (
    <div>
        <DetailModal>
        <div className="min-w-[400px] min-h-[200px] bg-white bg-clip-border w-full mx-1 my-2 rounded-md">
          <div className="flex py-3 px-4 items-center justify-between mb-0 bg-gray-100 border-b-[1px] border-solid border-slate-300/25 rounded-t-md">
            <h5 className="mb-0 font-bold text-slate-600 text-lg">Remark:</h5>
            <IoCloseOutline
              className="w-8 h-8 text-slate-500 hover:text-slate-400 transition-all duration-300 cursor-pointer"
              onClick={closeDetailModal}
            />
          </div>
          <div className="p-4">
            <p className="text-slate-500 break-words">{history}</p>
          </div>
            <div className="p-4 text-sm flex justify-between">
                <span>Added By: {addedName}</span>
                <span>Added At: {addedDate}</span>
            </div>
        </div>
      </DetailModal>
      <EditModal>
        <div className="min-w-0 bg-white bg-clip-border w-full mx-1 my-2 rounded-md">
          <div className="flex py-3 px-4 items-center justify-between mb-0 bg-gray-100 border-b-[1px] border-solid border-slate-300/25 rounded-t-md">
            <h5 className="mb-0 font-bold text-slate-600">{heading}</h5>
            <IoCloseOutline
              className="w-9 h-9 text-slate-500 hover:text-slate-400 transition-all duration-300 cursor-pointer"
              onClick={closeEditModal}
            />
          </div>
          <div className="m-1">
            <form onSubmit={handleSubmit}>
              <div className="grid md:grid-cols-2 gap-2 mb-1 pb-4 px-1 py-3 border-[1px] border-solid border-slate-300/25 rounded-md">
                <div>
                  <label className="ml-1 mb-1 text-slate-600">Stage</label>
                  <input
                    value={formValues.process_stage}
                    onChange={handleChange}
                    className="w-full p-2 text-slate-600 bg-clip-padding border-[1px] border-gray-300 border-solid rounded-md"
                    type="text"
                    name="process_stage"
                  />
                  {errors.process_stage&& (
                    <p className="form-errors">{errors.process_stage}</p>
                  )}
                </div>

                <div>
                  <label className="ml-1 mb-1 text-slate-600">Remarks</label>
                  <input
                    value={formValues.remarks}
                    onChange={handleChange}
                    className="w-full p-2 text-slate-600 bg-clip-padding border-[1px] border-gray-300 border-solid rounded-md"
                    type="text"
                    name="remarks"
                  />
                  {errors.remarks && (
                    <p className="form-errors">{errors.remarks}</p>
                  )}
                </div>

                <div>
                  <label className="ml-1 mb-1 text-slate-600">Event Date</label>
                  <input
                    value={formValues.event_date}
                    className="w-full p-2 text-slate-600 bg-clip-padding border-[1px] border-gray-300 border-solid rounded-md"
                    type="date"
                    onChange={handleChange}
                    name="event_date"
                  />
                  {errors.event_date && (
                    <p className="form-errors">{errors.event_date}</p>
                  )}
                </div>

                <div>
                  <label className="ml-1 mb-1 text-slate-600">Conduct by</label>
                  <select
                    value={formValues.conduct_by}
                    onChange={handleChange}
                    className="w-full p-2 text-slate-600 bg-clip-padding border-[1px] border-gray-300 border-solid rounded-md"
                    name="conduct_by"
                  >

                  <option value="">Select </option>
                    {employeeDetailList
                      ? employeeDetailList.map((val, index) => (
                          val.employee_status === "WORKING" &&
                          <option key={index} value={val.id}>
                          {val.employee_name}
                        </option>
                        ))
                      : null}
                  </select>
                  {errors.conduct_by && (
                    <p className="form-errors">{errors.conduct_by}</p>
                  )}
                </div>
              </div>
                <div className="flex gap-4 flex-wrap items-center py-3 px-4 border-t-[1px] border-solid border-gray-200">
                 <div className="flex-grow flex justify-between items-center">
                      <div className="flex gap-4 justify-end">
                        {enable ? (
                          <button
                            className="px-3 py-2 bg-emerald-500/70 hover:bg-emerald-500/90 text-slate-50 font-semibold rounded-md cursor-pointer  transition-all duration-300"
                            type="submit"
                          >
                            Save
                          </button>
                        ) : (
                          <div className="text-slate-50 font-semibold px-4 py-2 bg-emerald-500/70 hover:bg-emerald-500/90 rounded-md">
                            <RotatingLinesLoader />
                          </div>
                        )}
                        <button
                          type="button"
                          className="px-3 py-2 bg-gray-500/70 hover:bg-gray-500/90 text-slate-50 font-semibold rounded-md cursor-pointer transition-all duration-300"
                          onClick={closeEditModal}
                        >
                          Close
                        </button>
                      </div>
                  </div>
                </div>
            </form>
          </div>
        </div>
      </EditModal>

      <DeleteModal handleDelete={handleDelete} />

      <div className="flex flex-col justify-between lg:flex-row items-center mx-3">
          <div className="flex flex-col min-w-0 bg-white bg-clip-border w-full">
          <div className="mb-0 py-2 px-3 bg-gray-100 border-b-[1px] border-solid border-slate-300/25 flex flex-col md:flex-row items-start md:items-center justify-between flex-wrap">
             <h5 className="md:mb-0 max-md:mb-2 font-bold text-slate-600">
                <span className="text-emerald-500/90">
                  {recruitName }&#39;s
                </span>{" "}
                 History
            </h5>
          <div>
            <button
              className="cursor-pointer px-3 py-2 bg-emerald-500/70 border-none rounded-lg hover:bg-emerald-500/90 transition-all duration-300 text-slate-50"
              onClick={handleNew}
              style={{ marginLeft: '30px' }}
            >
              Add
            </button>
          </div>
          </div>
          <div className="card-body">
            <Pagination
                data = {applicants}
                setRowChange = {setRowChange}
                rowChangeUseEffect = {rowChangeUseEffect}
                pageChangeUseEffect = {pageChangeUseEffect}
                isLoading = {loading}
                count = {count}
                columns = {columns}
                page = {page}
                perPage = {perPage}
                setPerPage = {setPerPage}
                setPage = {setPage}
            />
          <div/>
          </div>
            <div className="flex flex-wrap items-center justify-end py-3 px-4 border-t-[1px] border-solid border-gray-200">
              <button
                className="px-3 py-2 bg-emerald-500/70 hover:bg-emerald-500/90 text-slate-50 font-semibold rounded-md transition-all duration-300"
                onClick={() => navigate("/recruits")}
              >
                <IoChevronBackSharp />
              </button>
            </div>
          </div>

      </div>
    </div>
  );

}

export default RecruitHistory;