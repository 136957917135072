import axios from "axios";

const getTokensFromProfile = () => {
  try {
    const profileString = localStorage.getItem("profile");
    if (!profileString) {
      return null;
    }

    const profile = JSON.parse(profileString);
    if (!profile.tokens) {
      return null;
    }

    const tokens = JSON.parse(profile.tokens.replaceAll("'", '"'));
    return tokens;
  } catch (error) {
    console.error("Error parsing profile:", error);
    return null;
  }
};

const baseURL = process.env.REACT_APP_BASE_URL;
const API = axios.create({ baseURL });


API.interceptors.request.use((req) => {
  const tokens = getTokensFromProfile();
  if (tokens) {
    req.headers.Authorization = `Bearer ${tokens.access}`;
  }
  return req;
});

API.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (!window.location.href.includes("/login")) {
      if (error.response.status === 401) {
        localStorage.clear();
        window.location.reload();
      } else {
        return Promise.reject(error);
      }
    }
  }
);

// API.interceptors.request.use((req) => {
//   if (localStorage.getItem('profile')) {
//     req.headers.Authorization = `Bearer ${
//       JSON.parse(JSON.parse(localStorage.profile).tokens.replaceAll("'", '"'))
//         .access
//     }`;
//   }
//   return req;
// });

export const signup = (formData) => API.post("auth/register/", formData);
export const resetPassword = (formData) =>
  API.post("/auth/request-reset-email/", formData);
export const signin = (formData) => API.post("auth/login/", formData);
export const passwordResetLink = (uidb64, token) =>
  API.get(`/auth/password-reset/?${uidb64}/${token}`);
export const createNewPassword = (formData) =>
  API.patch("auth/password-reset-complete", formData);
export const markAttendance = (formData) =>
  API.post("attendance/mark-attendance/", formData);
export const getAttendance = () =>
  API.get("attendance/check-today-attendance/");
export const getAllAttendance = (page_size, page) => API.get("attendance/get_attendance/", {
  params: {
    page_size: page_size,
    page: page,
  },
});
export const employee = ({ params }) => {
  return API.get("employees", { params: params });
};
export const deleteEmployee = (uidb64) => API.delete(`employees/${uidb64}/`);

export const deleteEmployeeHistory = (uidb64) => API.delete(`employee_history/${uidb64}/`);

export const employeeNames = () => API.get(`employees/unique-values/`)

export const getEmployee = (emp_id) => API.get('employees/get_employee', {
  params: {
    employee_id: emp_id
  }
})

export const getEmpHistory = (emp_id, page_size, page) => API.get(`employee_history`, {
  params: {
    emp_id: emp_id,
    page_size: page_size,
    page: page,
  },
})
export const getDepartment = (page_size, page) => API.get("department", {
  params: {
    page_size: page_size,
    page: page,
  },
});

export const addEmployeeHistory = (formData) =>
  API.post(`employee_history/`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });

export const editEmployeeHistory = (uidb64, formData) =>
  API.patch(`employee_history/${uidb64}/`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });

export const addEmployee = (formData) =>
  API.post(`employees/`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });

export const assignTeamLead = async (team_lead_id, employee_id,) => {
  API.post(`employees/assign-team-lead/`, {
    team_lead_id: team_lead_id,
    employee_id: employee_id
  }, {
    headers: {
      "Content-Type": "application/json",
    }
  });
};

export const editEmployee = (uidb64, formData) =>
  API.patch(`employees/${uidb64}/`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
export const getAllEmployeesAttendance = (emp_id, date, page_size, page, portal) => API.get("attendance", {
  params: {
    date: date,
    employee_id: emp_id,
    page_size: page_size,
    page: page,
    portal
  },
});
// export const filterAttendance = (date, emp_id, offset, limit, page) =>
//   API.get("attendance", {
//     params: {
//       date: date,
//       employee_id: emp_id,
//       offset : offset,
//       limit: limit,
//       page: page,
//     },
//   });
export const deleteDepartment = (uidb64) => API.delete(`department/${uidb64}/`);

export const departmentNames = () => API.get(`department/unique-values/`)
export const editDepartment = (uidb64, formData) =>
  API.put(`department/${uidb64}/`, formData);
export const addDepartment = (formData) => API.post(`department/`, formData);
export const addRecruiter = (formData) =>
  API.post(`recruits/`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
export const getRecruiter = (position, status, full_name, page_size, page) => {
  return API.get(`recruits`, {
    params: {
      position: position,
      status: status,
      full_name: full_name,
      page_size: page_size,
      page: page,
    },
  });
};

export const addRecruiterHistory = (formData) =>
  API.post(`recruits_history/`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });

export const deleteRecruiter = (uidb64) => API.delete(`recruits_history/${uidb64}/`);
export const editRecruiter = (uidb64, formData) =>
  API.patch(`recruits/${uidb64}/`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });

export const getRecruiterHistory = (id, page_size, page) => {

  return API.get(`recruits_history`, {
    params: {
      recruit_id: id,
      page_size: page_size,
      page: page,
    },
  });
};

export const deleteRecruiterHistory = (uidb64) => API.delete(`recruits_history/${uidb64}/`);
export const editRecruiterHistory = (uidb64, formData) =>
  API.patch(`recruits_history/${uidb64}/`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });

export const getAssets = (status, page_size, page) =>
  API.get("assets", {
    params: {
      status: status,
      page_size: page_size,
      page: page,
    },
  });
export const deleteAssets = (uidb64) => API.delete(`assets/${uidb64}/`);
export const editAssets = (uidb64, formData) =>
  API.patch(`assets/${uidb64}/`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
export const addAssets = (formData) =>
  API.post(`assets/`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
export const addAssignAsset = (formData) =>
  API.post(`assigned-asset/`, formData);
export const editAssignAsset = (uidb64, formData) =>
  API.put(`assigned-asset/${uidb64}/`, formData);
export const deleteAssignAsset = (uidb64) =>
  API.delete(`assigned-asset/${uidb64}/`);
export const dashboard = (portal) => API.get(`dashboard`, { params: { portal: portal } });
export const emp_dashboard = () => API.get(`dashboard/employee_dashboard/`);
export const tl_dashboard = () => API.get(`dashboard/team_lead_dashboard/`);
export const filterDashboard = (emp, portal) =>
  API.get(`dashboard`, {
    params: {
      emp: emp,
      portal
    },
  });
export const getLeaves = (page_size, page, portal) => API.get("leaves/get_leave/", {
  params: {
    page_size: page_size,
    page: page,
    portal
  },
});

export const getAllLeaves = (emp, status, leave_type, page_size, page, portal) =>
  API.get("leaves", {
    params: {
      employee_id: emp,
      status: status,
      leave_type: leave_type,
      page_size: page_size,
      page: page,
      portal
    },
  });

export const updateStatus = (uidb64, formData) =>
  API.patch(`leaves/${uidb64}/approve/`, formData);

export const editLeave = (uidb64, formData) =>
  API.patch(`leaves/${uidb64}/`, formData);

export const deleteLeave = (uidb64) => API.delete(`leaves/${uidb64}/`);

export const applyLeave = (formData) => API.post(`leaves/`, formData);

export const renderPayroll = (emp, month, year, page_size, page) => API.get(`payroll`, {
  params: {
    employee: emp,
    month: month,
    year: year,
    page_size: page_size,
    page: page,
  },
});

export const addPayroll = (formData) => API.post(`payroll/`, formData);

export const importPayroll = (formData) =>
  API.post(`payroll/import_payroll_details/`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });

export const emailPayroll = (formData) =>
  API.post(`payroll/send_mail/`, formData);

export const editPayroll = (uidb64, formData) =>
  API.put(`payroll/${uidb64}/`, formData);

export const deletePayroll = (uidb64) => API.delete(`payroll/${uidb64}/`);

// export const payrollFilter = (emp, month, year) =>
//   API.get(`payroll`, {
//     params: {
//       employee: emp,
//       month: month,
//       year: year,
//     },
//   });

export const empPayroll = (page_size, page) => API.get(`payroll/check_payroll/`, {
  params: {
    page_size: page_size,
    page: page,
  },
});

export const getPolicies = (page_size, page) => API.get(`policies/`, {
  params: {
    page_size: page_size,
    page: page,
  },
});

export const addPolicy = (formData) =>
  API.post(`policies/`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });

export const editPolicy = (uidb64, formData) =>
  API.patch(`policies/${uidb64}/`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });

export const deletePolicy = (uidb64) => API.delete(`policies/${uidb64}/`);

export const getTasks = (empId, employee, status, page_size, page) => API.get(`tasks/`, {
  params: {
    employee: empId,
    employee_name: employee,
    status: status,
    page_size: page_size,
    page: page,
  },
});

export const getAnnouncements = (page_size, page) => API.get(`announcements/`, {
  params: {
    page_size: page_size,
    page: page,
  },
});

export const addTasks = (formData) =>
  API.post(`tasks/`, formData);

export const editTasks = (uidb64, formData) =>
  API.put(`tasks/${uidb64}/`, formData);

export const deleteTasks = (uidb64) => API.delete(`tasks/${uidb64}/`);

export const statusUpdate = (uidb64, formData) =>
  API.patch(`tasks/${uidb64}/update_status/`, formData);
export const addAnnouncements = (formData) =>
  API.post(`announcements/`, formData);

export const editAnnouncements = (uidb64, formData) =>
  API.put(`announcements/${uidb64}/`, formData);

export const deleteAnnouncements = (uidb64) => API.delete(`announcements/${uidb64}/`);

export const getLatestAnnouncement = () =>
  API.get(`announcements/latest/`);







// API Calls for Project Management

// Fetch all projects with pagination and optional filters
export const getProjects = (params) => {
  return API.get(`/projects/`, { params });
};

// Add a new project
export const addProject = (formData) => 
  API.post("projects/", formData, {
    headers: { "Content-Type": "application/json" },
  });

// Get details of a specific project
export const getProjectDetails = (uidb64) => 
  API.get(`projects/${uidb64}/`);

// Edit project details
export const editProject = (uidb64, formData) =>
  API.patch(`projects/${uidb64}/`, formData, {
    headers: { "Content-Type": "application/json" },
  });

// Delete a project
export const deleteProject = (uidb64) => 
  API.delete(`projects/${uidb64}/`);

// Assign team members to a project
export const assignTeamToProject = async (project_id, team_member_ids) => {
  API.post(`projects/${project_id}/assign_team/`, {
    team_member_ids: team_member_ids
  }, {
    headers: {
      "Content-Type": "application/json",
    }
  });
};

export const createAssignment = (formData) => 
  API.post("assignments/", formData, {
    headers: { "Content-Type": "application/json" },
  });

export const updateAssignment = (assignmentId, formData) =>
  API.put(`assignments/${assignmentId}/`, formData, {
    headers: { "Content-Type": "application/json" },
  });

export const deleteAssignment = (assignmentId) =>
  API.delete(`assignments/${assignmentId}/`);

export const getProjectAssignments = (projectId) =>
  API.get(`assignments/`, {
    params: { project: projectId }
  });

export const getAllAccountManagers = () => API.get(`projects/account_managers/`);

// Update the status of a project
export const updateProjectStatus = (uidb64, status) =>
  API.patch(`projects/${uidb64}/status/`, { status });


export const getEmployeeProjects = (queryParams) => API.get(`assignments/employee_assignments/`, { params: queryParams });


export const getTeamLeadProjects = () => API.get(`assignments/teamlead_assignments/`)
