import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { BarsLoader } from "./Loaders";

const Pagination = ({
  data,
  filterChange,
  filterUseEffect,
  setRowChange,
  rowChangeUseEffect,
  pageChangeUseEffect,
  isLoading,
  count,
  columns,
  page,
  perPage,
  setPerPage,
  setPage,
}) => {
  useEffect(() => {
    if (filterUseEffect !== undefined) {
      filterUseEffect();
    }
  }, [filterChange]);

  useEffect(() => {
    if (typeof rowChangeUseEffect === 'function') {
      rowChangeUseEffect();
    }
  }, [perPage]);

  useEffect(() => {
    if (typeof pageChangeUseEffect === 'function') {
      pageChangeUseEffect();
    }
  }, [page]);

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePerRowsChange = (newPerPage) => {
    setRowChange(true);
    setPerPage(newPerPage);
  };

  const lastIndex = page * perPage;
  const firstIndex = lastIndex - perPage;
  const mydata = data?.slice(firstIndex, lastIndex);

  return (
    <DataTable
      columns={columns}
      data={mydata}
      progressPending={isLoading}
      progressComponent={<BarsLoader />}
      pagination
      paginationServer
      paginationRowsPerPageOptions={[20, 50, 100]}
      paginationTotalRows={count}
      paginationPerPage={perPage}
      onChangeRowsPerPage={handlePerRowsChange}
      onChangePage={handlePageChange}
      fixedHeaderScrollHeight="500px"
      highlightOnHover
    />
  );
};
export default Pagination;
